import React from 'react';

import Layout from '../components/layout';
import Menu from '../components/menuComponent';
import Privacy from '../components/privacyComponent';

export default () => (
  <Layout
    title="Privacy Policy | Wow Interior Design"
    description="Please view our privacy policy on how we use your data."
  >
    <Menu />
    <Privacy />
  </Layout>
);
