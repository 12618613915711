/* eslint-disable max-len */
import React from 'react';

import privacyStyles from './privacy.module.css';

export default () => (
  <div className={privacyStyles.privacyOuterContainer}>
    <div className={privacyStyles.privacyContainer}>
      <div className={privacyStyles.privacyHeader}>
        <h1>PRIVACY POLICY</h1>
        <p>Effective 25th May 2018</p>
      </div>

      <h2>1. PURPOSE OF THIS NOTICE</h2>
      <p>{'This notice describes how we collect and use personal data about you, in accordance with the General Data Protection Regulation (GDPR), the Data Protection Act 2018 and any other national implementing laws, regulations and secondary legislation, as amended or updated from time to time, in the UK (\'Data Protection Legislation\'). Please read the following carefully to understand our practices regarding your personal data and how we will treat it.'}</p>

      <h2>2. ABOUT US</h2>
      <p>{'Wow Interior Design are a bespoke furniture design and fitting company based at Suite 4 Mottis Business Centre, Cheriton High Street, Folkestone, Kent, CT19 4QJ. For the purpose of the Data Protection Legislation and this notice, we are the \'data controller\'. This means that we are responsible for deciding how we hold and use personal data about you. We are required under the Data Protection Legislation to notify you of the information contained in this privacy notice. We have appointed a Head of Privacy. Our Head of Privacy is our Data Protection Point of Contact and is responsible for assisting with enquiries in relation to this privacy notice or our treatment of your personal data. Should you wish to contact our Data Protection Point of Contact you can do so using the contact details noted at paragraph 12 (Contact Us), below.'}</p>

      <h2>3. HOW WE MAY COLLECT YOUR PERSONAL DATA</h2>
      <p>We obtain personal data about you, for example, when:</p>
      <ul>
        <li>you request a proposal from us in respect of the services we provide;</li>
        <li>you or your employer or our clients engages us to provide our services and also during the provision of those services;</li>
        <li>you contact us by email, telephone, post or social media (for example when you have a query about our services) or</li>
        <li>from third parties and/or publicly available resources (for example, from your employer or from Companies House).</li>
      </ul>

      <h2>4. THE KIND OF INFORMATION WE HOLD ABOUT YOU</h2>
      <p>The information we hold about you may include the following:</p>
      <ul>
        <li>your personal details (such as your name, address, telephone number and/or email address);</li>
        <li>details of contact we have had with you in relation to the provision, or the proposed provision, of our services;</li>
        <li>details of any services you have received from us</li>
        <li>our correspondence and communications with you</li>
        <li>information about any complaints and enquiries you make to us</li>
        <li>information from research, surveys, and marketing activities;</li>
        <li>Information we receive from other sources, such as publicly available information, information provided by your employer or our clients or information from our member network firms.</li>
      </ul>

      <h2>5. HOW WE USE PERSONAL DATA WE HOLD ABOUT YOU</h2>
      <p>We may process your personal data for purposes necessary for the performance of our contract with you or your employer or our clients and to comply with our legal obligations.</p>
      <p>We may process your personal data for the purposes necessary for the performance of our contract with our clients. This may include processing your personal data where you are an employee, subcontractor, supplier or customer of our client.</p>
      <p>We may process your personal data for the purposes of our own legitimate interests provided that those interests do not override any of your own interests, rights and freedoms which require the protection of personal data. This includes processing for marketing, business development, statistical and management purposes.</p>
      <p>We may process your personal data for certain additional purposes with your consent, and in these limited circumstances where your consent is required for the processing of your personal data then you have the right to withdraw your consent to processing for such specific purposes.</p>
      <p>Please note that we may process your personal data for more than one lawful basis depending on the specific purpose for which we are using your data.</p>

      <h3>Situations in which we will use your personal data</h3>
      <p>We may use your personal data in order to:</p>
      <ul>
        <li>carry out our obligations arising from any agreements entered into between you or your employer or our clients and us (which will most usually be for the provision of our services);</li>
        <li>carry out our obligations arising from any agreements entered into between our clients and us (which will most usually be for the provision of our services) where you may be a subcontractor, supplier or customer of our client;</li>
        <li>provide you with information related to our services and our events and activities that you request from us or which we feel may interest you, provided you have consented to be contacted for such purposes;</li>
        <li>seek your thoughts and opinions on the services we provide; and</li>
        <li>notify you about any changes to our services.</li>
        <li>In some circumstances we may anonymise or pseudonymise the personal data so that it can no longer be associated with you, in which case we may use it without further notice to you.</li>
        <li>If you refuse to provide us with certain information when requested, we may not be able to perform the contract we have entered into with you. Alternatively, we may be unable to comply with our legal or regulatory obligations.</li>
        <li>We may also process your personal data without your knowledge or consent, in accordance with this notice, where we are legally required or permitted to do so.</li>
      </ul>

      <h3>Data retention</h3>
      <p>We will only retain your personal data for as long as is necessary to fulfil the purposes for which it is collected. When assessing what retention period is appropriate for your personal data, we take into consideration:</p>
      <ul>
        <li>the requirements of our business and the services provided;</li>
        <li>any statutory or legal obligations;</li>
        <li>the purposes for which we originally collected the personal data;</li>
        <li>the lawful grounds on which we based our processing</li>
        <li>the types of personal data we have collected</li>
        <li>the amount and categories of your personal data; and</li>
        <li>whether the purpose of the processing could reasonably be fulfilled by other means.</li>
      </ul>

      <h3>Change of purpose</h3>
      <p>Where we need to use your personal data for another reason, other than for the purpose for which we collected it, we will only use your personal data where that reason is compatible with the original purpose.</p>
      <p>Should it be necessary to use your personal data for a new purpose, we will notify you and communicate the legal basis which allows us to do so before starting any new processing.</p>

      <h2>6. DATA SHARING</h2>
      <h3>Why might you share my personal data with third parties?</h3>
      <p>We will share your personal data with third parties where we are required by law, where it it necessary to administer the relationship between us or where we have another legitimate interest in doing so.</p>
      <h3>Which third-party service providers process my personal data?</h3>
      <p>{'"Third parties" includes third-party service providers and other entities within our group or the members of our firm\'s network. The following activities are carried out by third-party service providers:'}</p>
      <p>IT and cloud services, professional advisory services, administration services, marketing services and banking services. </p>
      <p>All of our third-party service providers are required to take commercially reasonable and appropriate security measures to protect your personal data. We only permit our third-party service providers to process your personal data for specified purposes and in accordance with our instructions.</p>
      <h3>What about other third parties?</h3>
      <p>We may share your personal data with other third parties, for example in the context of the possible sale or restructuring of the business. We may also need to share your personal data with a  regulator or to otherwise comply with the law.</p>

      <h2>7. TRANSFERRING PERSONAL DATA OUTSIDE THE EUROPEAN ECONOMIC AREA (EEA)</h2>
      <p>We may transfer the personal data we collect about you to the United States of America which is outside of the EEA in order to perform our contract with you:</p>
      <p>AND</p>
      <p>There is an adequacy decision by the European Commission in relation to this country therefore it will be deemed to provide an adequate level of protection for your personal information for the purpose of the Data Protection Legislation.</p>

      <h2>8. DATA SECURITY</h2>
      <p>We have put in place commercially reasonable and appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>
      <p>We have put in place procedures to deal with any suspected data security breach and will notify you and any applicable regulator of a suspected breach where we are legally required to do so.</p>

      <h2>9. RIGHTS OF ACCESS, CORRECTION, ERASURE AND RESTRICTION</h2>
      <h3>Your duty to inform us of changes</h3>
      <p>It is important that the personal data we hold about you is accurate and current. Should your personal information change, please notify us of any changes of which we need to be made aware by contacting us, using the contact details below.</p>
      <h3>Your rights in connection with personal data</h3>
      <p>Under certain circumstances, by law you have the right to:</p>
      <ul>
        <li>Request access to your personal data. This enables you to receive details of the personal data we hold about you and to check that we are processing it lawfully.</li>
        <li>Request correction of the personal data that we hold about you.</li>
        <li>Request erasure of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have exercised your right to object to processing (see below).</li>
        <li>Object to processing of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this basis. You also have the right to object where we are processing your personal information for direct marketing purposes.</li>
        <li>Request the restriction of processing of your personal data. This enables you to ask us to suspend the processing of personal data about you, for example if you want us to establish its accuracy or the reason for processing it.</li>
        <li>Request the transfer of your personal data to you or another data controller if the processing is based on consent, carried out by automated means and this is technically feasible.</li>
      </ul>
      <p>If you want to exercise any of the above rights, please email our Data Protection Point of Contact at info@wowinteriordesign.com. </p>
      <p>You will not have to pay a fee to access your personal data or to exercise any of the other rights. However, we may charge a reasonable fee if your request for access is clearly unfounded or excessive. Alternatively, we may refuse to comply with the request in such circumstances. </p>
      <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access the information or to exercise any of your other rights. This is another appropriate security measure to ensure that personal information is not disclosed to any person who has no right to receive it.</p>

      <h2>10. RIGHT TO WITHDRAW CONSENT</h2>
      <p>In the limited circumstances where you may have provided your consent to the collection, processing and transfer of your personal data for a specific purpose (for example, in relation to direct marketing that you have indicated you would like to receive from us), you have the right to withdraw your consent for that specific processing at any time. To withdraw your consent, please email our data protection point of contact at info@wowinteriordesign.com</p>
      <p>Once we have received notification that you have withdrawn your consent, we will no longer process your personal information (personal data) for the purpose or purposes you originally agreed to unless we have another legitimate basis for doing so in law</p>

      <h2>11. CHANGES TO THIS NOTICE</h2>
      <p>Any changes we may make to our privacy notice in the future will be here.</p>
      <p>This privacy notice was last updated on 25th May 2018.</p>

      <h2>12. CONTACT US</h2>
      <p>If you have any questions regarding this notice or if you would like to speak to us about the manner in which we process your personal data, please contact our Data Protection Point of Contact Jack Matthews, by email at info@wowinteriordesign.com or telephone on 01303 847480</p>
      <p>{'You also have the right to make a complaint to the Information Commissioner\'s Office (ICO), the UK supervisory authority for data protection issues, at anytime. The ICO\'s contact details are as follows:'}</p>
      <ul style={{ listStyleType: 'none' }}>
        <li>{'Information Commissioner\'s Office'}</li>
        <li>Wycliffe House</li>
        <li>Water Lane</li>
        <li>Wilmslow</li>
        <li>Cheshire</li>
        <li>SK9 5AF</li>
      </ul>
      <ul style={{ listStyleType: 'none' }}>
        <li>Telephone - 0303 123 1113 (local rate) or 01625 545 745</li>
        <li><a href="https://ico.org.uk/concerns">Website - https://ico.org.uk/concerns</a></li>
      </ul>

    </div>
  </div>
);
